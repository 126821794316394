<template>
  <v-container>
    <v-form ref="online-course-form" @submit.prevent="formSubmit">
      <v-row>
        <v-col cols="12" class="d-flex">
          <v-btn @click.prevent="$router.replace({ name: 'onlineCourse' })" plain :disabled="isFetching">
            <v-icon>{{ icons.mdiChevronLeft }}</v-icon>
            <div>上一頁</div>
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-card rounded="lg" class="pa-5">
            <v-card-subtitle>
              <div class="page-title">{{ $route.meta.title }}</div>
            </v-card-subtitle>
            <v-card-text class="my-5">
              <v-row>
                <v-col md="12" lg="6" cols="12">
                  <h3 class="mb-3">課程封面</h3>
                  <FormButtonUpload
                    :uploadFile.sync="courseCover"
                    :showUploadFileName="false"
                    :showUploadPreviewImg="true"
                  ></FormButtonUpload>
                  <div class="error-msg mt-2">{{ uploadCourseCoverError }}</div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <form-control
                    inputType="string"
                    v-model="title"
                    label="課程標題"
                    :required="true"
                    :disabled="isFetching"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <form-control
                    inputType="string"
                    v-model="courseCode"
                    label="課程編號"
                    :required="true"
                    :disabled="isFetching"
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <form-control
                    inputType="select"
                    v-model="category"
                    label="課程分類"
                    :required="true"
                    :disabled="isFetching"
                    :options="courseCategoryList"
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <form-control
                    inputType="select"
                    v-model="status"
                    label="狀態"
                    :required="true"
                    :disabled="isFetching"
                    :options="$statusList"
                  />
                </v-col>
                <!--   <v-col cols="12" md="6">
                  <form-control
                    inputType="number"
                    v-model="price"
                    label="價錢(HK$)"
                    :required="true"
                    :inputFloatingOnly="true"
                    :validateFloating="true"
                    :disabled="isFetching"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <form-control
                    inputType="select"
                    v-model="sku"
                    label="程式內購買價錢"
                    :required="false"
                    :disabled="isFetching"
                    :options="skuList"
                  />
                </v-col> -->
                <v-col cols="12" md="12">
                  <form-control
                    inputType="textarea"
                    v-model="shortDescription"
                    label="短簡介"
                    :required="true"
                    :disabled="isFetching"
                    :rows="6"
                  />
                </v-col>
                <v-col cols="12" md="12">
                  <h3 class="mb-3">簡介</h3>
                  <QuillEditor editorID="editor" :html.sync="description"></QuillEditor>
                  <div class="error-msg mt-2">{{ descriptionError }}</div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <TestimonialTable
            :testimonialHeader="testimonialHeader"
            :testimonialsList.sync="testimonials"
          ></TestimonialTable>
        </v-col>
      </v-row>

      <v-row class="pt-6" v-if="$validate.DataValid(id)">
        <v-col cols="12">
          <v-card rounded="lg" class="pa-5">
            <v-card-subtitle class="d-flex align-center">
              <div class="page-title mr-5">線上課程單元</div>
              <v-btn
                color="primary"
                class="px-6"
                depressed
                small
                :disabled="isFetching"
                :to="{
                  name: 'onlineCourseModuleCreate',
                  params: { course_id: id },
                }"
                >新增</v-btn
              >
            </v-card-subtitle>

            <v-card-text class="my-5">
              <v-row>
                <DraggableTable
                  :tableHeader="videoHeader"
                  :data="videoData"
                  @setOrder="setVideoOrder"
                  @dragTableAction="videoAction"
                ></DraggableTable>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row class="pt-10">
        <v-col cols="12" class="d-flex align-center">
          <v-btn color="primary white--text" class="px-6 mr-2" small depressed :disabled="isFetching" type="submit">
            儲存
          </v-btn>

          <FormDeleteBtn @removeAction="removeCourse"></FormDeleteBtn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import FormControl from '@/components/form/FormControl.vue'
import { mdiChevronLeft } from '@mdi/js'
import DraggableTable from '@/components/DraggableTable'
import FormButtonUpload from '@/components/form/FormButtonUpload.vue'
import TestimonialTable from '@/components/TestimonialTable.vue'
import QuillEditor from '@/components/QuillEditor'
import FormDeleteBtn from '@/components/form/FormDeleteBtn.vue'

export default {
  name: 'OnlineCourseDetail',
  components: {
    FormControl,
    DraggableTable,
    FormButtonUpload,
    TestimonialTable,
    QuillEditor,
    FormDeleteBtn,
  },
  setup() {
    return {
      icons: {
        mdiChevronLeft,
      },
    }
  },
  data() {
    return {
      courseCover: '',
      title: '',
      status: '',
      category: '',
      description: '',
      shortDescription: '',
      //price: '',
      courseCode: '',
      //sku: '',
      descriptionError: '',
      saveDescriptionImage: [],

      saveCourseCover: '',
      uploadCourseCoverError: '',

      testimonialHeader: [
        { text: '客戶名稱', value: 'name', minWidth: '125px', width: '15%' },
        { text: '評價', value: 'description', minWidth: '300px', width: '70%' },
        { text: '', value: 'editBtn', width: '15%' },
        { text: '', value: 'deleteBtn', width: '15%' },
      ],
      testimonials: [],

      courseCategoryList: [],
      submitSuccess: false,

      videoHeader: [
        { text: '單元標題', value: 'video_title', minWidth: '200px', width: '35%' },
        { text: '影片長度', value: 'duration', minWidth: '200px', width: '30%' },
        { text: '', value: 'viewBtn', width: '15%' },
        { text: '', value: 'deleteBtn', width: '15%' },
      ],
      videoData: [],
      removeVideoList: [],

      dbSKUList: [],
    }
  },
  computed: {
    ...mapState({
      isFetching: state => state.request.isFetching,
    }),

    id: function() {
      return this.$route.params.id
    },

    skuList: function() {
      return this.dbSKUList.map(obj => {
        return { text: obj.price, value: obj.sku }
      })
    },
  },
  methods: {
    ...mapActions(['setFetching'], 'request'),
    ...mapActions(['setDialogMessage', 'setShowDialog']),

    async getCourseCategory() {
      try {
        const payload = {
          limit: this.tablePageLimit,
          page: this.tablePage - 1,
        }

        const result = await this.$XHR.api('get_course_category', payload)
        this.$func.log('-----Get Course Category List-----')
        this.$func.log(result)

        const temp = []

        for (let i = 0; i < result.data.length; i++) {
          const resultData = result.data[i]

          temp.push({
            value: resultData.id,
            text: resultData.category_name,
          })
        }

        this.courseCategoryList = temp
      } catch (error) {
        this.$func.log('-----Get Course Category List fail-----')
        this.$func.log(error)

        let msg = ''

        if (error.data === 'admin verification fail') {
          this.forceLogout()
        } else if (error.data === 'no permission') {
          msg = '沒有權限'
        }

        if (this.$validate.DataValid(msg)) {
          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: msg,
            type: 'error',
            refresh: false,
            redirect: '',
          })
        }
      } finally {
        this.setFetching(false)
      }
    },
    async getSKU() {
      try {
        const result = await this.$XHR.api('get_iap_price_list', {})
        this.$func.log('-----Get SKU List-----')
        this.$func.log(result)

        this.dbSKUList = result
      } catch (error) {
        this.$func.log('-----Get SKU fail-----')
        this.$func.log(error)
      }
    },
    async getCourseDetail() {
      let courseError = false
      let message = '資料不存在'

      try {
        const payload = {
          id: parseInt(this.id),
          verify_token: this.getAdminToken(),
          get_video_only: true,
          course_mode: 'online',
        }

        const result = await this.$XHR.api('cms_get_course_by_id', payload)
        this.$func.log('-----Get Course Detail-----')
        this.$func.log(result)

        this.courseCover = result.cover
        this.saveCourseCover = result.cover
        this.title = result.course_name
        this.category = result.course_category_id
        this.status = result.status
        this.description = result.description
        this.shortDescription = result.short_description
        //this.price = result.price
        this.courseCode = result.course_code
        // this.sku = result.sku

        this.testimonials = result.testimonials

        this.videoData = result.videos

        this.saveDescriptionImage = this.$func.getMediaInsideHTMLString(this.description)

        this.$store.dispatch('toggleLoadingPage', false)
      } catch (error) {
        this.$func.log('-----Get Course Fail-----')
        this.$func.log(error)

        if (error.data === 'admin verification fail') {
          this.forceLogout()
        } else if (error.data === 'no permission') {
          courseError = true
          message = '沒有權限'
        } else {
          courseError = true
        }
      } finally {
        this.setFetching(false)
      }

      if (courseError) {
        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message,
          type: 'error',
          refresh: false,
          redirect: 'onlineCourse',
        })
      }
    },

    async formSubmit() {
      if (!this.isFetching) {
        this.setFetching(true)

        const isValid = this.$refs['online-course-form'].validate()
        this.uploadCourseCoverError = ''
        this.descriptionError = ''

        if (!this.$validate.DataValid(this.courseCover)) {
          this.uploadCourseCoverError = '請上傳課程封面'
        }

        if (!this.$validate.DataValid(this.description)) {
          this.descriptionError == '請輸入簡介'
        }

        if (
          !isValid ||
          this.$validate.DataValid(this.uploadCourseCoverError) ||
          this.$validate.DataValid(this.descriptionError)
        ) {
          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: '請先填滿所有必填項目',
            type: 'error',
            refresh: false,
            redirect: '',
          })
          this.setFetching(false)
          return
        }

        try {
          const payload = {
            verify_token: this.getAdminToken(),
            course_code: this.courseCode,
            course_category_id: parseInt(this.category),
            course_name: this.title,
            course_mode: 'online',
            short_description: this.shortDescription,
            description: this.description,
            //  price: parseFloat(this.price),
            cover: this.courseCover,
            testimonials: this.testimonials,
            status: this.status,
            //  sku: this.sku,
          }

          let key = ''
          if (this.$validate.DataValid(this.id)) {
            payload.id = parseInt(this.id)
            key = 'cms_update_course'
          } else {
            key = 'cms_add_course'
          }

          const result = await this.$XHR.api(key, payload)
          this.$func.log('-----Update Online Course-----')
          this.$func.log(result)

          this.submitSuccess = true

          if (this.$validate.DataValid(this.id)) {
            if (this.videoData.length > 0 || this.removeVideoList.length > 0) {
              const videoPayload = {
                verify_token: this.getAdminToken(),
                course_id: parseInt(this.id),
                video_id_list: this.videoData.map(obj => {
                  return obj.id
                }),
                remove_id_list: this.removeVideoList,
              }

              const videoResult = await this.$XHR.api('cms_update_video_order', videoPayload)
              this.$func.log('-----Update Video Order-----')
              this.$func.log(videoResult)
            }
          }

          this.setDialogMessage({
            message: '儲存成功',
            isError: false,
            returnLink: { name: 'onlineCourse' },
          })

          this.setShowDialog(true)
          this.setFetching(false)
        } catch (error) {
          this.$func.log('-----Update Online Course Fail-----')
          this.$func.log(error)

          let message = '儲存失敗'

          if (error.data === 'admin verification fail') {
            this.forceLogout()
          } else if (error.data === 'no permission') {
            message = '沒有權限'
          } else if (error.data === 'repeat course code') {
            message = '課程編號已被使用'
          } else if (error.data === 'course category not found') {
            message = '課程分類不存在'
          } else if (error.data === 'course does not exist') {
            message = '課程不存在'
          } else if (error.data === 'video not found') {
            message = '部份影片不存在'
          }

          this.setFetching(false)
          this.submitSuccess = false
          this.setDialogMessage({
            message: message,
            isError: true,
            returnLink: null,
          })
          this.setShowDialog(true)
        }
      }
    },

    async removeCourse(action) {
      if (action.value) {
        if (!this.isFetching) {
          this.setFetching(true)

          try {
            const payload = {
              id: parseInt(this.id),
              verify_token: this.getAdminToken(),
            }

            const result = await this.$XHR.api('cms_remove_course', payload)
            this.$func.log('-----Remove Online Course-----')
            this.$func.log(result)

            this.submitSuccess = false

            this.setDialogMessage({
              message: '刪除成功',
              isError: false,
              returnLink: { name: 'onlineCourse' },
            })

            this.setShowDialog(true)
            this.setFetching(false)
          } catch (error) {
            this.$func.log('-----Remove Online Course Fail-----')
            this.$func.log(error)

            let message = '刪除失敗'

            if (error.data === 'admin verification fail') {
              this.forceLogout()
            } else if (error.data === 'no permission') {
              message = '沒有權限'
            } else if (error.data === 'student exists, cannot delete this course') {
              message = '已有報名學生，不可刪除'
            } else if (error.data === 'course not found') {
              message = '課程不存在'
            }

            this.setFetching(false)
            this.submitSuccess = false
            this.setDialogMessage({
              message: message,
              isError: true,
              returnLink: null,
            })
            this.setShowDialog(true)
          }
        }
      }
      action.value = false
    },

    async setVideoOrder(data) {
      this.videoData = data
    },

    async videoAction(type, index) {
      if (type === 'view') {
        this.$router.push({
          name: 'onlineCourseModuleDetail',
          params: { course_id: this.id, video_id: this.videoData[index].id },
        })
      } else if (type === 'delete') {
        this.removeVideoList.push(this.videoData[index].id)
        this.videoData.splice(index, 1)
      }
    },
  },
  async created() {
    this.$store.dispatch('toggleLoadingPage', true)
    const check = await this.checkUserData()
    if (check) {
      this.setFetching(true)
      this.getCourseCategory()
      //  this.getSKU()

      if (!this.$validate.DataValid(this.id)) {
        this.$store.dispatch('toggleLoadingPage', false)
      } else {
        this.getCourseDetail()
      }
    }
  },
  async beforeDestroy() {
    let removeList = []
    if (this.$store.getters.removedImageList.length > 0) {
      removeList = removeList.concat(this.$store.getters.removedImageList)
    }

    if (this.submitSuccess) {
      if (this.saveCourseCover !== this.courseCover) {
        const index = removeList.indexOf(this.courseCover)
        if (index !== -1) {
          removeList.splice(index, 1)
        }

        removeList.push(this.saveCourseCover)
      }

      const imgList = this.$func.getMediaInsideHTMLString(this.description)
      for (let i = 0; i < removeList.length; i++) {
        const index = imgList.indexOf(removeList[i])
        if (index !== -1) {
          removeList.splice(index, 1)
        }
      }
    } else {
      for (let i = 0; i < removeList.length; i++) {
        const index = this.saveDescriptionImage.indexOf(removeList[i])
        if (index !== -1) {
          removeList.splice(index, 1)
        }
      }
    }

    if (removeList.length > 0) {
      const removeImage = await this.$XHR.api('cms_remove_media', {
        verify_token: this.getAdminToken(),
        remove_image_list: removeList,
      })

      this.$func.log('--- remove image success ---')
      this.$func.log(removeImage)
    }

    this.$store.dispatch('resetRemovedImage', [])
  },
}
</script>
